.folderContainer {
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
    height: 100%;
    width: 90%;
    margin: auto;
    padding-top: 5px;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 00px; 

    > article {
        width: 100%;
        margin-bottom: 20px;
    }

    p.title {
        margin-top: 10px;
        color: white;
        font-size: 12px;
    }
}