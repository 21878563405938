.dictaphone {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    will-change: transform;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: .2s all ease-out;
    background: white;
    transform-origin: 55% 15%;
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    
    .topDictaphone {
        display: flex;
        flex-direction: column;
        padding-left: 10%;
        padding-right: 10%;
        box-sizing: border-box;
        padding-top: 50px;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        
        h2 {
            margin-left: 10px;
            font-size: 32px;
            color: black;
            font-weight: 800;
        }

        img {
            width: 12px;
            height: auto;
        }

        .top {
            display: flex;
            width: 100%;
            align-items: center;
            box-sizing: border-box;
        }

        .inputContainer {
            position: relative;
            width: 100%;
            margin-top: 20px;
            border-bottom: solid 1px #b9b9be;
            margin-bottom: 30px;
            box-sizing: border-box;
            padding-bottom: 15px;

            &:before {
                content: '';
                position: absolute;
                background: url('./loupe.png');
                left: 10px;
                top: 8px;
                width: 12px;
                height: 12px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                z-index: 3;
            }

            input {
                width: 100%;
                box-sizing: border-box;
                color: #8E8E92;
                background: #EEEEEF;
                border: none;
                border-radius: 5px;
                z-index: 0;
                padding: 5px;
                padding-left: 30px;
                position: relative;
            }
        }
    }

    .dictaphoneContent {
        display: flex;
        flex-direction: column;
        width: 80%;
        align-items: center;

        .articleDictaphone {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 20px;

            h3 {
                font-size: 21px;
                margin-bottom: 10px;
                font-weight: 800;
            }

            .infos {
                display: flex;
                justify-content: space-between;
                font-size: 300;
                color: #7F7F7F;
                width: 100%;
                font-size: 14px;
            }

            img {
                width: 20px;
                align-self: center;
                margin: 20px auto;
            }
        }
    }
}
