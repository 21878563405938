.gallerie {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    will-change: transform;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: .2s all ease-out;
    transform-origin: 20% 30%;
    
    .topGallerie {
        display: flex;
        align-self: flex-start;
        padding-left: 2%;
        align-items: center;
        padding-bottom: 30px;
        padding-top: 50px;
        width: 100%;
        box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1);
        
        h2 {
            margin-left: 30px;
            font-size: 36px;
            color: black;
            font-weight: 800;
        }
    }

    .gallerieContainer {
        display: flex;
        flex-wrap: wrap;
        width: 96%;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between;
        position: relative;
        max-height: 100%;
        overflow-y: scroll;
        transition: .1s all ease-in-out;

        &.blury {
            filter: blur(10px)
        }
    }

    .blurryBg {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;

        .close {
            color: white;
            font-size: 72px;
            position: absolute;
            right: 30px;
            top: 50px;
            width: 30px;
        }

        .bigPicture {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 96%;
            transform: translate3d(-50%, -50%, 0);
        }
    }
    
    article {
        width: 49%;
        margin-bottom: 10px;

        img {
            width: 100%;
            height: 200px;
            object-fit: cover;
        }
    }
}