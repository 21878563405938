.MusicItem {
    width: 47%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    flex-direction: column;
    text-decoration: none;
    color: black;

    &:nth-child(2n) {
        margin-right: 6%;
    }


    .cover {
        width: 100%;
        height: auto;
        border-radius: 4px;
    }

    p {
        margin: 10px 0 20px;
        font-size: 21px;
        color: black;
    }
}