.topBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    color: white;
    width: 100%;
    margin: 0 auto;
    padding: 10px 3%;
    box-sizing: border-box;
    z-index: 5;
    font-family: 'SF-Pro';

    > p {
        margin-left: 20px;
    }

    .right_topBar {
        display: flex;
        align-items: center;

        img {
            width: 16px;
            filter: invert(1);
        }
    }

    .batterie {
        font-size: 12px;
        margin-left: 5px;
    }

    .external_battery {
        width: 20px;
        border-radius: 3px;
        border: solid 1px rgb(100, 100, 100);
        height: 10px;
        margin-left: 6px;
        position: relative;
        margin-right: 3px;

        .internal_battery {
            width: 17%;
            background: white;
            height: calc(100% - 2px);
            border-top-left-radius: 1px;
            border-bottom-left-radius: 1px;
            margin: 1px;
        }

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -3px;
            width: 1px;
            height: 6px;
            background: rgb(100, 100, 100);
            border-top-right-radius: 1px;
            border-bottom-right-radius: 1px;
        }
    }

    .reseau {
        margin-left: 6px;
        font-size: 12px;
    } 

    &.black {
        color: black;

        .external_battery {
            .internal_battery {
                background: black;
            }
        }

        .right_topBar {
            img {
                filter: invert(0);
            }
        }
    }
}