@font-face {
  font-family: "SF-Pro";
  font-weight: 400;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
}

@font-face {
  font-family: "SF-Pro";
  font-weight: 800;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-bold-webfont.woff");
}

@font-face {
  font-family: "SF-Pro";
  font-weight: 300;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-ultralight-webfont.woff");
}



* {
  font-family: 'SF-Pro';
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.body,
.fullApp {
  /* Break the flow */
  position: absolute;
  top: 0px;

  /* Give them all the available space */
  width: 100%;
  height: 100%;

  /* Remove the margins if any */
  margin: 0;

  /* Allow them to scroll down the document */
  overflow-y: hidden;
}

.body {
  /* Sending body at the bottom of the stack */
  z-index: 1;
}

html {
  touch-action: pan-down;
}

.fullApp {
  /* Making the wrapper stack above the body */
  z-index: 2;
}

.appContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  box-sizing: content-box;
}

input, input:before, input:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
 } 

html, body, #root {
  height: 100%;
}

.fullApp {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgb(0, 0,0);
  background: linear-gradient(0deg, rgba(0, 0, 0,1) 0%, rgba(38,38,38,1) 100%);

  > footer {
    display: none;

    @media screen and (min-width: 728px) {
      display: initial;
      position: absolute;
      left: 20px;
      width: auto;
      bottom: 20px;

      ul {
        background: none;
        flex-direction: column;
      }
    }
  }
}

.App {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(22, 21, 22);
  background-image: url('./assets/img/LETO@FIFOU-3702.jpg');
  background-position: center;
  background-size: cover;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);

  footer {
    @media screen and (min-width: 728px) {
      display: none;
    }
  }

  @media screen and (min-width: 728px) {
    border-radius: 20px;
    position: fixed;
    width: 360px;
    height: 780px;
    max-height: 90%;
    box-shadow: 0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919, 0px 0px 0px 20px #111;
    
    &:before,
    &:after{
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    
    // frontal camera/speaker frame
    &:before {
      top: 0px;
      width: 42%;
      height: 30px;
      background-color: #1f1f1f;
      z-index: 15;
      border-radius: 0px 0px 40px 40px;
    }
    
    i,
    b,
    s,
    span {
      position: absolute;
      display: block;
      color: transparent;
      z-index: 16;
    }
    
    // speaker
    i {
      top: 0px;
      left: 50%;
      transform: translate(-50%, 6px);
      height: 8px;
      width: 15%;
      background-color: #101010;
      border-radius: 8px;
      box-shadow: inset 0px -3px 3px 0px rgba(256, 256, 256, 0.2);
    }
    
    // camera
    b {
      left: 10%;
      top: 0px;
      transform: translate(180px, 4px);
      width: 12px;
      height: 12px;
      background-color: #101010;
      border-radius: 12px;
      box-shadow: inset 0px -3px 2px 0px rgba(256, 256, 256, 0.2);
      
      &:after {
        content: '';
        position: absolute;
        background-color: #2d4d76;
        width: 6px;
        height: 6px;
        top: 2px;
        left: 2px;
        top: 3px;
        left: 3px;
        display: block;
        border-radius: 4px;
        box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.5);
      }
    }
    
    // time
    s {
      top: 50px;
      color: #fff;
      text-align: center;
      text-decoration: none;
      width: 100%;
      font-size: 70px;
      font-weight: 100;
      padding-top: 60px;
    }
    
    // action buttons
    span {
      bottom: 50px;
      width: 40px;
      height: 40px;
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      left: 30px;
      
      & + span {
        left: auto;
        right: 30px;
      }
    }
  } 

  @media screen and (max-width: 728px) {
    i, s, b, span {
      display: none;
    }
  }
}

.slider {
  height: 100%;
}

.slider .item {
  width: 100%;
  height: 100%;
}

.back {
    width: 12px;
    margin-left: -10px;
    padding: 10px;
}

main:not(.introduction) {
  // padding-top: 50px;
  font-family: 'SF-Pro';
  box-sizing: border-box;
  
  @media screen and (min-width: 728px) {
    border-radius: 20px;
  }
}

.slick-dots li button::before {
  color: white!important;
}

.slick-slider {
  height: 90%;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  height: 100%;
}

.slick-dots {
  bottom: 10px;
}

.slick-dots li button::before {
  font-size: 12px;
}

.break {
  position: absolute;
  bottom: 100px;
  right: 40px;
  transform: rotate(20deg);
  z-index: 20;
  pointer-events: none;
  width: 130px;
}

.radiusIcon {
  border-radius: 15px;
}






// TRANSITION CSS REACT
// EXAMPLE

.example-enter {
  transform: scale(0.01);
  will-change: transform;
}

.example-enter.example-enter-active {
  transform: scale(1);
  transition: transform 200ms ease-in-out;
}

.example-exit {
  transform: scale(1);
  will-change: transform;
}

.example-exit.example-exit-active {
  transform: scale(0.01);
  transition: transform 200ms ease-in-out;
}

// NOTIF

.notif-enter {
  transform: translateY(-200%);
  will-change: transform;
}

.notif-enter.notif-enter-active {
  transform: translateY(0);
  transition: transform 500ms ease-in-out;
}

.notif-exit {
  transform: translateY(0);
  will-change: transform;
}

.fromRight-exit.fromRight-exit-active {
  transform: translateY(-100%);
  transition: transform 300ms ease-in-out;
}

// FROM RIGHT
.fromRight-enter {
  transform: translateX(100%);
  will-change: transform;
}

.fromRight-enter.fromRight-enter-active {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}

.fromRight-exit {
  transform: translateX(0);
  will-change: transform;
}

.fromRight-exit.fromRight-exit-active {
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;
}






// DESKTOP REPONSIVE

.desktopLogo {
  display: none;

  @media screen and (min-width: 728px) {
    display: initial;
    position: absolute;
    top: 50px;
    left: 50px;
    filter: invert(1);
    width: 100px;
  }
}

.rightActions {
    display: none;
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "SF-Pro";
    color: white;

    @media screen and (min-width: 982px) {
      display: flex;
    }

    img {
        filter: invert(1);
        width: 150px;
    }

    a, button {
        font-family: "SF-Pro";
        border: solid 1px white;
        border-radius: 30px;
        text-transform: uppercase;
        margin-top: 30px;
        color: white;
        text-decoration: none;
        background: none;
        padding: 15px 30px;
        box-sizing: border-box;
        width: 250px;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
    }

    button {
        color: black;
        background: white;
    }
}