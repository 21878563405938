.contact {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.wmgsdk-modal-dialogue-close {
    position: absolute;
    top: 10px!important;
    right: 10px!important;
    filter: invert(1);
    z-index: 10;
}

.wmgsdk-modal-content {
    font-family: "SF-Pro";
    height: 100%;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    max-height: 90vh!important;
    overflow-y: scroll;

    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 30px;
        width: 100%;

        div {
            width: 100%;
            box-sizing: border-box;
        }

        label {
            text-transform: capitalize;
            display: block;
            margin-right: 10px;
            width: 100%;
        }

        label + input {
            margin-bottom: 20px;
            margin-top: 5px;
            background: #EEEEEE;
            border: none;
            padding: 5px 20px 5px 5px;
            border-radius: 3px;
            width: 100%;
        }

        ul {
            li {
                margin-bottom: 0;
                margin-top: 20px;

                &:first-of-type {
                    margin-top: 0;
                } 
                
                input {
                    margin-top: 0;
                }

                label {
                    p {
                        font-size: 10px;
                    }
                }
            }
        }
    }
}

.wmgsdk-modal {
    .wmgsdk-modal-dialogue div.opt-in-button button {
        background: black;
        color: white;
        border: none;
        padding: 15px 50px;
        border-radius: 3px;
    }
}