.folderIcon {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 30px;
    
    img {
        width: 78%;
        max-width: 65px;
        margin: 0 auto;
    }

    p {
        font-size: 12px;
        color: white;
        margin-top: 10px;
    }
}