.bottomBar {
    border-radius: 15px;
    background: rgba(255, 255, 255, .6);
    width: 96%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;

    img {
        width: 70px;
        position: relative;
    }

    .smsContainer {
        position: relative;

        &.notif {
            &:before {
                font-family: "SF-Pro";
                z-index: 3;
                content: '1';
                position: absolute;
                top: -5px;
                right: -5px;
                background: #FF2626;
                color: white;
                border-radius: 50%;
                width: 20px;
                text-align: center;
                vertical-align: center;
                line-height: 20px;
                height: 20px;
            }
        }
    } 
}