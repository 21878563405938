.SMSItem {
    width: 80%;
    border-bottom: solid 1px #EEE;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .avatar {
        width: 50px;
        height: 50px;
        position: absolute;
        left: -65px;
        background: lightgray;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    img.externalIcon {
        width: 30px;
        position: absolute;
        left: -12%;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        opacity: .6;
    }

    .mainInfos {
        h2 {
            color: black;
            font-size: 21px;
            font-weight: 800;
            margin-top: 10px;
            margin-bottom: 5px;

            &.miss {
                color: rgb(228, 70, 66);
            }
        }
    
        p {
            color: gray;
            font-size: 16px;
            margin-top: 5px;
            margin-bottom: 10px;
        }

        p.textInfo {
            font-size: 14px;
        }
    }

    .date {
        color: gray;
        font-size: 18px;
        margin-right: 25px;
        margin-left: 20px;
    }
}