.sms {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    will-change: transform;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: .2s all ease-out;
    transform-origin: 60% 90%;
    overflow: hidden;

    .smsList {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        overflow-y: scroll;
        padding-top: 10px;
    }
}

.conversation {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    transform: translateX(100%);
    transition: .2s all ease-in-out;

    &.open {
        transform: translateX(0);
    }
}

.topSMS {
    display: flex;
    align-self: flex-start;
    justify-content: center;
    padding-bottom: 30px;
    align-items: center;
    padding-top: 50px;
    width: 100%;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1);

    .nameAndAvatar {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        .avatar {
            width: 50px;
            height: 50px;
            left: -65px;
            background: lightgray;
            color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
        }

        > p {
            margin-top: 10px;
        }
    }

    .back {
        position: absolute;
        left: 10%;
    }
    
    h2 {
        margin-left: 30px;
        font-size: 36px;
        color: black;
        font-weight: 800;
    }
}

.messages {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    align-items: flex-start;

    .message {
        margin-top: 30px;
        border-radius: 30px;
        background: #EEE;
        padding: 10px 20px;

        &:first-of-type {
            margin-top: 40px;
        }

        &.other {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                z-index: 0;
                bottom: 0;
                left: -7px;
                height: 20px;
                width: 20px;
                background: #EEE;
                border-bottom-right-radius: 15px;
            }

            &:after {
                content: "";
                position: absolute;
                z-index: 1;
                bottom: 0;
                left: -10px;
                width: 10px;
                height: 20px;
                background: white;
                border-bottom-right-radius: 10px;
              }
              
        }

        &.me {
            background: rgb(0, 120, 254);
            color: white;
            align-self: flex-end;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                z-index: 0;
                bottom: 0;
                right: -8px;
                height: 20px;
                width: 20px;
                background: rgb(0, 120, 254);
                border-bottom-left-radius: 15px;
            }

            &:after {
                content: "";
                position: absolute;
                z-index: 1;
                bottom: 0;
                right: -10px;
                width: 10px;
                height: 20px;
                background: white;
                border-bottom-left-radius: 10px;
            }  
        }
    }
}