.bigPicture {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    text-decoration: none;

    p {
        margin: 10px 0;
        font-size: 32px;
        margin-bottom: 30px;
        font-weight: 800;
        text-decoration: none;
        color: black;
    }

    img {
        width: 100%;
        border-radius: 10px;
    }
}