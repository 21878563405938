li.trackItem {
    width: 100%;
    border-bottom: solid 1px #EEE;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 20px 2%;

    article {
        display: flex;
        align-items: center;

        > img {
            width: 20px;
            height: auto;
            margin-left: 30px;
            margin-right: 30px;
        }
    }

    div {
        display: flex;
        flex-direction: column;

        .title {
            font-weight: 800;
            font-size: 21px;
            margin-bottom: 5px;
        }

        .artiste {
            color: #555;
        }
    }

    .hour {
        margin-right: 30px;
    }
}