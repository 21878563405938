@keyframes wiggle {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }

.music {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    will-change: transform;
    background: white;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: .2s all ease-out;
    transform-origin: 90% 90%;
    overflow: hidden;

    .musicList {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        overflow-y: scroll;
        padding-top: 40px;
        padding-bottom: 50px;
    }
}

.topMusic {
    display: flex;
    align-self: flex-start;
    justify-content: flex-start;
    padding-bottom: 30px;
    align-items: center;
    padding-top: 50px;
    width: 100%;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1);



    .back {
        margin-left: 3%;
    }
    
    h2 {
        margin-left: 30px;
        font-size: 36px;
        color: black;
        font-weight: 800;
    }
}

.lockScreenContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-family: 'SF-Pro';
    z-index: 10;
    // background: rgba(0, 0, 0, .3);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .lockScreen {
        width: 80%;
        height: 400px;
        background: white;
        border-radius: 15px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, .4);

        .close {
            position: absolute;
            top: 30px;
            right: 30px;
            filter: invert(1);
            width: 20px;
            height: 20px;
        }

        &.wiggle {
            animation: wiggle 0.5s;
            animation-iteration-count: infinite; 
        }

        .locker {
            margin-bottom: 30px;
        }

        input[type="text"] {
            padding: 10px;
            width: 80%;
            margin: 20px auto;
            border: none;
            background:rgba(0, 0, 0, .1);
            border-radius: 3px;
        }

        p {
            width: 70%;
            margin: 0 auto;
            text-align: center;
        }

        input[type="submit"] {
            margin-top: 30px;
            background: rgb(50, 50, 50);
            border: none;
            color: white;
            padding: 10px 40px;
            border-radius: 5px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }
    }
}
