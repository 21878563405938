.notes {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    will-change: transform;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: .2s all ease-out;
    transform-origin: 35% 12%;
    background: white;

    .topCall {
        display: flex;
        align-self: flex-start;
        padding-left: 8%;
        padding-bottom: 15px;
        align-items: center;
        padding-top: 50px;
        box-sizing: border-box;
        width: 100%;
        // box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1);
        
        h2 {
            margin-left: 30px;
            font-size: 36px;
            color: black;
            font-weight: 800;
        }
    }

    .mainNotes {
        padding: 30px;
        overflow-y: auto;

        h2 {
            font-size: 24px;
            font-weight: 800;
            margin-bottom: 30px;
        }

        p {
            margin-bottom: 10px;
        }
    }  
}
