.homepage {
    margin: 0 auto;
    position: relative;
    height: 100%;
    padding-top: 50px;
    width: 100%;

    .blury {
        transition: .1s all ease-in-out;
        filter: blur(10px);
    }

    a {
        color: rgba(255, 255, 255, 1);
        text-decoration: none;
        font-size: 32px;
        text-align: center;
    }
}