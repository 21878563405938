footer {
    width: 100%;

    ul {
        width: 100%;
        font-size: 14px;
        display: flex;
        background: black;
        color: white;
        font-family: "SF-Pro";
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        box-sizing: border-box;

        @media screen and (min-width: 782px) {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
        }
    
        li {
            margin-bottom: 5px;
        }

        li, p {
            color: white;
        }
        
        a {
            color: white;
        }
    }
}