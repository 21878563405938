.popupcontainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    background: rgba(0, 0, 0, .4);
    height: 100%;
    width: 100%;
    font-family: 'SF-Pro';

    @media screen and (min-width: 728px) {
        border-radius: 20px;
    }

    .popup {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 250px;
        background: rgba(230, 230, 255, .96);
        border-radius: 15px;
        height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        padding: 0;

        h2 {
            margin: 30px auto 0;
            font-size: 19px;    
            font-weight: 800;
        }
        
        p {
            margin-top: -10px;
        }

        .topSection {
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .close {
            border-radius: 0;
            border: none;
            width: 100%;
            border-top: solid 1px rgb(29, 117, 211);
            color: rgb(29, 117, 211);
            font-weight: 800;
            font-size: 18px;
            background: none;
            height: 50px;
            display: flex;
            align-items: center;
            text-align: center;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            user-select: none;

            &:active {
                background: rgba(194, 194, 216, 0.96);
                color: rgb(25, 97, 175);
            }

            p {
                width: 100%;
                margin-top: 0;
            }
        }
    }
}