.notification {
    position: absolute;
    width: 96%;
    height: auto;
    padding: 20px 0;
    border-radius: 10px;
    font-family: "SF-Pro";
    background: rgba(#D6D6D6, 0.95);
    // margin: 20px;
    top: 40px;
    // transform: translateY(-200%);
    transition: .2s all ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 20;
    justify-content: flex-start;

    .topNotif {
        display: flex;
        width: 90%;
        margin: 0 auto;
        justify-content: space-between;
        font-weight: thin;
        color: rgb(100, 100, 100);

        .left {
            display: flex;
        }

        img {
            width: 20px;
            margin-right: 10px;
        }
    }

    > h2, > p {
        width: 90%;
        margin: 0 auto;
    }

    > h2 {
        font-weight: 800;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}