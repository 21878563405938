.call {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    z-index: 5;
    will-change: transform;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    transition: .2s all ease-out;
    box-sizing: border-box;
    transform-origin: 10% 90%;

    .content {
        overflow-y: scroll;
        box-sizing: content-box;
        width: 100%;
        
        .topCall {
            display: flex;
            align-self: flex-start;
            padding-left: 15%;
            padding-bottom: 15px;
            align-items: center;
            padding-top: 50px;
            box-sizing: border-box;
            width: 100%;
            // box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1);
            
            h2 {
                margin-left: 30px;
                font-size: 36px;
                color: black;
                font-weight: 800;
            }
        }
    
        .callList {
            padding-bottom: 20px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }

    .topBar {
        border-bottom: solid 1px #EEE;
        background: white;
        background: rgb(249, 249, 249);
        z-index: 12;
    }
}