.calendrierIconContainer {
    width: 25%;
    height: auto;
    margin-bottom: 20px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    flex-direction: column;
    position: relative;
    justify-content: center;

    .calendrierIcon {
        background: white;
        padding: 11px 0;
        width: 80%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border-radius: 20%;
        max-width: 65px;
        margin: 0 auto;

        .day {
            font-size: 18px;
            color: #EA4E3D;
        }

        .chiffre {
            font-size: 24px;
        }
    }

    &.notif {
        .calendrierIcon {
            &::before {
                content: '1';
                position: absolute;
                top: -5px;
                right: -5px;
                background: #FF2626;
                color: white;
                border-radius: 50%;
                width: 20px;
                text-align: center;
                vertical-align: center;
                line-height: 20px;
                height: 20px;
            }
        }
    }
}