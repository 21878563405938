.dayCalendrier {
    width: 100%;
    height: auto;
    overflow-y: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    z-index: 7;
    display: flex;
    flex-direction: column;
    align-items: center;
    will-change: transform;
    transition: .2s all ease-out;
    transform-origin: 35% 50%;
    overflow-y: scroll;
    padding-bottom: 30px;

    .visuelSoiree {
        width: 90%;
        margin-top: 30px;
    }

    .topBar {
        z-index: 8;
    }
    
    .topCalendrier {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        justify-content: flex-start;
        padding-bottom: 30px;
        align-items: center;
        padding-top: 50px;
        width: 100%;
        top: 0;
        background: #F7F6F7;
        // box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1);
    
        .back {
            position: absolute;
            left: 6%;
            width: 30px;
            padding-top: 0;
        }
        
        h2 {
            margin-left: 30px;
            font-size: 32px;
            color: #EA4E3D;
            // font-weight: 800;
            margin-bottom: 20px;
        }
    }

    .dayCalendrier-content {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
        padding-bottom: 30px;

        h3 {
            font-weight: bolder;
            font-size: 32px;
            margin-top: 30px;
        }

        .previousEvent {
            color: #C4C4C6;
            font-size: 12px;
            border-bottom: solid 1px #F7F6F7;
            padding: 20px 50px;
            margin-top: 30px;
        }

        .explication {
            margin-top: 30px;
            width: 80%;
            font-size: 16px;
            line-height: 21px;
        }

        button {
            margin-top: 30px;
            background: #EA4E3D;
            border: none;
            color: white;
            padding: 15px 55px;
            border-radius: 5px;
            margin-bottom: 20px;
        }

        .info {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 30px;
        }
    }
}