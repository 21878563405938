.introduction {
    background-color: rgb(22, 21, 22);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    @media screen and (min-width: 728px) {
      border-radius: 20px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
  
      @media screen and (min-width: 728px) {
        border-radius: 20px;
      }  
    }
}