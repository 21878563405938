.maps {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    will-change: transform;
    transition: .2s all ease-out;
    transform-origin: 35% 90%;
    overflow-y: scroll;
    
    .topMaps {
        display: flex;
        align-self: flex-start;
        justify-content: flex-start;
        padding-bottom: 30px;
        align-items: center;
        padding-top: 50px;
        width: 100%;
        position: fixed;
        top: 0;
        background: white;
        box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1);
    
        .back {
            margin-left: 3%;
        }
        
        h2 {
            margin-left: 30px;
            font-size: 36px;
            color: black;
            font-weight: 800;
        }
    }

    .mapsContent {
        width: 100%;
        height: 100%;
        overflow-y: scroll;

        img {
            width: 96%;
            height: auto;
            margin-top: 140px;
        }
    }

    .map-container {
        height: 100%;
    }

    .mapboxgl-ctrl {
        display: none;
    }
}