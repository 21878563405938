.meteo {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    will-change: transform;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: .2s all ease-out;
    transform-origin: 65% 30%;
    background-repeat: no-repeat;
    background-size: cover;

    .back {
        position: absolute;
        top: 10%;
        left: 10%;
        filter: invert(1);
        width: 20px;
    }

    .mainMeteo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 40%;
        color: white;
        text-align: center;

        .city {
            font-size: 32px;
        }

        .temps {
            font-size: 20px;
            font-weight: 100;
            margin-top: 10px;
        }

        .temperature {
            font-size: 128px;
        }

        .minMax {
            font-weight: 300;
        }
    }

    .nextHours {
        color: white;
        display: flex;
        width: 70%;
        margin: 0 auto;
        justify-content: space-between;
        font-size: 24px;
        margin-top: 50px;

        .hour {
            display: flex;
            width: calc(100% / 6);
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-weight: 100;

            p {
                font-family: "SF-Pro";
                font-weight: 300;
                font-size: 15px;
            }

            &.bold {
                font-weight: 400;
            }

            img {
                margin: 10px auto;
            }
        }
    }
}
