.fakeIconContainer {
    width: 25%;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;

    .fakeIcon {
        width: 78%;
        max-width: 65px;
        margin: 0 auto;

        img {
            width: 100%;
            max-width: 65px;
            margin: 0 auto;
            height: auto;
        }
    }
}