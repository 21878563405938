.endgame {
    background-color: black;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;

    .lowBatterie {
        width: 100%;
        // display: none;

        @media screen and (min-width: 982px) {
            display: initial;
        }
    }

    button, a, .logo {
        display: none;
    }

    &.step2 {
        .lowBatterie {
            width: 100%;
            display: none;
    
            @media screen and (min-width: 982px) {
                display: initial;
            }
        }



        button, a, .logo {
            display: initial;

            @media screen and (min-width: 982px) {
                display: none;
            }
        }
    }

    .logo {
        filter: invert(1);
        width: 150px;
    }

    a, button {
        font-family: "SF-Pro";
        border: solid 1px white;
        border-radius: 30px;
        margin-top: 30px;
        color: white;
        text-decoration: none;
        background: none;
        padding: 15px 30px;
        box-sizing: border-box;
        width: 250px;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        text-transform: uppercase;
    }

    button {
        color: black;
        background: white;
        margin-top: 100px;
    }
}