.dixSeptPourcentContent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-family: "SF-Pro";
    z-index: 11;
    width: 100%;
    bottom: 0;
    transform-origin: 39% 45%;
    background: white;
    transition: .3s all ease-in-out;

    ul {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .player {
        background: #EEE;
        border-top: solid 1px lightgray;
        padding: 20px;
        display: flex;
        width: 100%;
        height: 100px;
        position: absolute;
        bottom: 0;
        left: 0;
        box-sizing: border-box;
        align-items: center;
        flex-direction: row;
        justify-content: space-around;

        img {
            width: 30px;

            &.goOn {
                transform: rotate(180deg);
            }
        }
    }
}